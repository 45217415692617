import React, { useState } from "react";
import { createContext } from "react";
import { getCookieConsentValue } from "react-cookie-consent";

const defaultState = {
  cookiesStatus: undefined,
  setCookiesStatus: () => null,
};

export const CookiesContext = createContext(defaultState);

export const CookieContextProvider = ({ children }) => {
  const [cookiesStatus, setCookiesStatus] = useState(
    getCookieConsentValue("gatsby-gdpr-google-analytics") || undefined
  );

  return (
    <CookiesContext.Provider value={{ cookiesStatus, setCookiesStatus }}>
      {children}
    </CookiesContext.Provider>
  );
};
