exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chi-siamo-index-js": () => import("./../../../src/pages/chi-siamo/index.js" /* webpackChunkName: "component---src-pages-chi-siamo-index-js" */),
  "component---src-pages-contatti-index-js": () => import("./../../../src/pages/contatti/index.js" /* webpackChunkName: "component---src-pages-contatti-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-cookies-index-js": () => import("./../../../src/pages/privacy-cookies/index.js" /* webpackChunkName: "component---src-pages-privacy-cookies-index-js" */),
  "component---src-pages-servizi-index-js": () => import("./../../../src/pages/servizi/index.js" /* webpackChunkName: "component---src-pages-servizi-index-js" */),
  "component---src-pages-thanks-index-js": () => import("./../../../src/pages/thanks/index.js" /* webpackChunkName: "component---src-pages-thanks-index-js" */),
  "component---src-templates-corsi-page-template-js": () => import("./../../../src/templates/corsiPageTemplate.js" /* webpackChunkName: "component---src-templates-corsi-page-template-js" */),
  "component---src-templates-post-page-template-js": () => import("./../../../src/templates/postPageTemplate.js" /* webpackChunkName: "component---src-templates-post-page-template-js" */)
}

