/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "@fontsource/lato";
import React from "react";
import { IconContext } from "react-icons";
import { CookieContextProvider } from "./src/contexts/cookies.context";

export const wrapRootElement = ({ element }) => {
  return (
    <CookieContextProvider>
      <IconContext.Provider
        value={{
          className: "icons",
          style: { verticalAlign: "middle", paddingBottom: "0.2em" },
          size: "1.3em",
        }}
      >
        {element}
      </IconContext.Provider>
    </CookieContextProvider>
  );
};
